import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography as MuiTypography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box as MuiBox, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { WarningIcon } from 'shared/icons/WarningIcon';
import { DeactivationDriverDTO } from 'shared/modules/driver/DriverDTO';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { trackDriversEvent } from '../data/DriversAnalytics';
import {
  useDriver,
  useDriversAPI,
  useDriversUndelieveredLoadsCount,
} from '../data/DriversAPI';

const StyledForm = styled(Form)`
  overflow-x: hidden;
`;

interface DriverDeactivateDialogContentProps {
  guid?: string;
  onDismiss: () => void;
  onSuccess?: (nextDriverGUID?: string) => void;
}

const List = styled.ul`
  padding-inline-start: 20px;
  margin: 0;
`;

const Box = styled(MuiBox)`
  padding: 6px 8px;
`;

const Typography = styled(MuiTypography)`
  font-size: 13px;
`;

export function DriverDeactivateDialogContent({
  guid,
  onDismiss,
  onSuccess = onDismiss,
}: DriverDeactivateDialogContentProps) {
  const isMobile = useResponsiveValue(true, false);
  const { data } = useCarrierSettings();
  const isSeatBasedPricingEnabled = useFlag('seats_based_pricing');
  const { data: driver } = useDriver(guid);
  const { data: undeliveredLoads } = useDriversUndelieveredLoadsCount(guid);
  const { addSnackbar } = useSnackbarStack();
  const { deactivateDriver } = useDriversAPI();
  const formik = useFormikEnhanced({
    initialValues: {
      deactivationReason: '',
    },
    onSubmit: (values) => {
      if (guid) {
        return deactivateDriver({
          guid,
          reason: values.deactivationReason,
        });
      }
      return Promise.reject(new Error('Please select driver!'));
    },
    onSubmitSuccess: (response: APIResponse<DeactivationDriverDTO>) => {
      trackDriversEvent({
        name: 'Carrier Deactivated Driver',
        driverGuid: driver?.guid,
        reason: formik.values.deactivationReason,
      });
      onSuccess();
      addSnackbar(
        response.user_message || `${driver?.name || 'Driver'} deactivated`,
      );
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const undeliveredLoadsCount = undeliveredLoads?.undelivered || 0;

  return (
    <FormikProvider value={formik}>
      <StyledForm aria-label="deactivate driver">
        <DialogTitle>Deactivate {driver?.name}?</DialogTitle>
        <DialogContent>
          <Stack space="small">
            {undeliveredLoadsCount > 0 && (
              <Box borderRadius="small" backgroundColor="Yellow50">
                <Inline space="xsmall" verticalAlign="center">
                  <WarningIcon fontSize="small" />
                  <Typography>
                    This driver has {undeliveredLoadsCount} undelivered loads.
                    The driver will lose access to these loads.
                  </Typography>
                </Inline>
              </Box>
            )}
            {isSeatBasedPricingEnabled && (
              <Box borderRadius="small" backgroundColor="Blue50">
                <Columns space="xsmall">
                  <Column width="content">
                    <MuiBox marginTop="xxsmall">
                      <Info fontSize="small" color="primary" />
                    </MuiBox>
                  </Column>
                  <Column>
                    <Typography>
                      Your subscription plan will be adjusted, and you will not
                      be charged for this driver on your next billing date. The
                      seat purchased can be used for any new driver until the
                      next billing cycle.
                    </Typography>
                  </Column>
                </Columns>
              </Box>
            )}
            <List>
              <li>
                All of the orders related to the driver will remain in your
                account.
              </li>

              <li>
                The driver will no longer be able to see or access loads
                associated to your Carrier TMS account.
              </li>

              {data?.is_paying && !isSeatBasedPricingEnabled && (
                <li>
                  Your subscription plan will be adjusted and you will not be
                  charged for this driver.
                </li>
              )}

              <li>You can invite the driver back at anytime in the future.</li>
            </List>
            <FormikMaxLengthTextField
              name="deactivationReason"
              autoFocus={true}
              fullWidth={true}
              multiline={true}
              maxLength={512}
              label="Reason for Deactivation"
              placeholder="Tell us why you are deactivating this driver"
              helperText={
                <TextBox color="secondary" variant="caption">
                  This will help us improve user experience
                </TextBox>
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          {isMobile ? (
            <Stack>
              <Button
                fullWidth={true}
                variant="neutral"
                onClick={onDismiss}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth={true}
                variant="critical"
                pending={formik.isSubmitting}
              >
                Yes, Deactivate
              </Button>
            </Stack>
          ) : (
            <>
              <Button
                variant="neutral"
                onClick={onDismiss}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
              <Button
                pending={formik.isSubmitting}
                variant="critical"
                type="submit"
              >
                Yes, Deactivate
              </Button>
            </>
          )}
        </DialogActions>
      </StyledForm>
    </FormikProvider>
  );
}
